:root {
  @apply font-normal text-white;
  @apply font-encode;
}

h1,
h2,
h3 {
  & b {
    @apply font-bold text-primary;
  }
  @apply font-encode;
}

/* HERO */
.text-heading-hero {
  @apply text-5xl font-bold md:text-6xl lg:text-7xl;
}
/* SECTION HEADING */
.text-heading-about {
  @apply text-5xl font-black md:text-5xl xl:text-6xl;
  & b {
    @apply font-bold text-primary;
  }
}

.text-heading-item {
  @apply text-3xl font-black leading-7 lg:text-3xl lg:leading-9;
  & b {
    @apply font-bold text-primary;
  }
}

.text-heading-work-item {
  @apply text-4xl font-black leading-[2.2rem] md:text-5xl md:leading-[2.9rem];
  & b {
    @apply font-bold text-primary;
  }
}

.heading-1 {
  @apply text-5xl font-black leading-[3rem] lg:text-6xl lg:leading-[3.8rem];
  & b {
    @apply font-bold text-primary;
  }
}
.heading-2 {
  @apply text-4xl font-black leading-8 lg:text-5xl lg:leading-[3rem];
  & b {
    @apply font-bold text-primary;
  }
}
.heading-3 {
  @apply text-2xl font-black lg:text-3xl;
  & b {
    @apply font-bold text-primary;
  }
}
.heading-4 {
  @apply text-xl font-black lg:text-2xl;
  & b {
    @apply font-bold text-primary;
  }
}

.heading-work-2 {
  @apply text-4xl font-black md:text-6xl xl:text-7xl;
  & b {
    @apply font-bold text-primary;
  }
}
.heading-work-3 {
  @apply text-4xl font-black md:text-5xl xl:text-5xl;
  & b {
    @apply font-bold text-primary;
  }
}

.work-heading {
  @apply text-2xl leading-[2.2rem] md:text-3xl md:leading-[2.8rem] lg:text-3xl lg:leading-[2.8rem];
  & b {
    @apply font-bold text-primary;
  }
}

a {
  @apply focus-visible:outline-primary;
}
