.cursor {
  position: fixed;
  top: -24px;
  left: -23px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid rgba(242, 195, 1, 1);
  background-color: rgba(242, 195, 1, 0.3);
  opacity: 0;
  z-index: 99999;
  user-select: none;
  pointer-events: none;
}
.cursor-dot {
  position: fixed;
  top: -9px;
  left: -8px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid rgba(242, 195, 1, 1);
  background-color: rgba(242, 195, 1, 1);
  opacity: 1;
  z-index: 99999;
  user-select: none;
  pointer-events: none;
}

.cursor-tooltip {
  position: fixed;
  top: 20px;
  left: 20px;

  background-color: rgba(242, 195, 1, 1);
  opacity: 0;
  z-index: 99999;
  user-select: none;
  pointer-events: none;
  width: 400px;
  max-width: calc(100svw - 40px);
  color: black;
}
