.btn {
  &.btn-custom-primary {
    @apply relative border-none bg-transparent pb-9 pt-5 align-middle font-bold text-primary hover:bg-transparent lg:px-16;

    &.on-light {
      @apply text-white;
    }

    &.on-black {
      @apply text-black;
    }

    &.bg-white & {
      @apply text-black;
    }

    > span {
      @apply absolute inset-0;

      &:before,
      &:after {
        @apply absolute h-[3px] w-[3px] bg-primary transition-all content-[""];
      }

      &:first-child {
        &:before {
          @apply bottom-0 left-0 h-[50%];
        }

        &:after {
          @apply right-0 top-0 h-[52%];
        }
      }

      &:last-child {
        &:before {
          @apply right-0 top-0 w-[60%];
        }

        &:after {
          @apply bottom-0 left-0 w-[60%];
        }
      }
    }

    &:focus,
    &:hover {
      span {
        &:before,
        &:after {
          @apply h-1 w-1;
        }

        &:first-child {
          &:before,
          &:after {
            @apply bottom-0 top-0 h-full;
          }
        }

        &:last-child {
          &:before,
          &:after {
            @apply w-full;
          }
        }
      }
    }
  }
}

.stairs {
  .transition-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 99999;
    div {
      position: relative;
      height: 100%;
      width: 100%;
      background-color: #130c1d;
    }
  }

  .transition-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #261434;
    z-index: 99999;
    pointer-events: none;
    top: 0;
    left: 0;
  }
}
