.hero {
  @apply relative flex min-h-screen w-full items-center bg-white pt-20 lg:min-h-[90vh];

  .hero-title-container {
    position: relative;
    width: 100%;
    height: 80px;

    .title {
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      margin-bottom: 0;
      text-transform: unset;
      opacity: 0;
      height: 40px;
      overflow: hidden;
    }
  }

  .hero-text {
    opacity: 0;
    overflow: hidden;
    transform: translateY(20px);
  }
  .hero-button {
    opacity: 0;
    overflow: hidden;
    transform: translateY(20px);
  }
}
