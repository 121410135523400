@layer components {
  /* MENU */
  .menu-content {
    @apply hidden gap-5 lg:flex;
  }
  .menu-content a {
    @apply block py-2 transition-all hover:text-primary;
  }
  .page-light .menu-content a {
    @apply text-black;
  }
  .menu-content a[aria-current='page'] {
    @apply pointer-events-none text-primary opacity-100;
  }
  .menu-trigger {
    @apply fixed right-0 top-0 z-10 mt-2 flex p-4 lg:right-auto lg:top-5 lg:mt-0 lg:p-0;
  }

  /* MENU LOGO */
  .page-light header .menu-trigger {
    @apply invert;
  }

  /* Menu modal */
  dialog.menu-modal[open] {
    @apply pointer-events-auto translate-x-0 opacity-100;
  }
  dialog.menu-modal {
    @apply block overflow-auto;
    @apply fixed top-0 h-screen max-h-full w-screen max-w-full;
    @apply translate-x-16 py-8 opacity-0 transition-all;
    @apply bg-gradient pointer-events-none text-white;
  }
}
