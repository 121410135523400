body:has(.cm--box[aria-hidden='false']),
body:has(.pm--box[aria-hidden='false']) {
  overflow: hidden;
}
#cc-main:has(.cm--box[aria-hidden='false']) {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background: var(--cc-overlay-bg);
}

#cc-main {
  --cc-btn-primary-bg: #f2c301;
  --cc-btn-primary-hover-bg: #f2c301ee;
  --cc-btn-primary-border-color: transparent;
  --cc-btn-primary-hover-border-color: transparent;

  --cc-separator-border-color: #261434;
  --cc-btn-secondary-bg: #261434;
  --cc-btn-secondary-hover-bg: #30273b;
  --cc-btn-secondary-hover-border-color: #261434;
  --cc-btn-secondary-border-color: #261434;
  --cc-btn-secondary-color: #ffffff;
  --cc-btn-secondary-hover-color: #ffffff;

  --cc-secondary-color: #ffffff;

  --cc-cookie-category-block-bg: #261434;
  --cc-cookie-category-block-border: #261434;
  --cc-separator-border-color: #261434;
  --cc-btn-secondary-bg: #261434;
  --cc-btn-secondary-hover-bg: #30273b;
  --cc-btn-secondary-hover-border-color: #261434;
  --cc-btn-secondary-border-color: #261434;
  --cc-btn-secondary-color: #ffffff;
  --cc-btn-secondary-hover-color: #ffffff;
  --cc-cookie-category-block-bg: #261434;
  --cc-cookie-category-block-border: #261434;
  --cc-cookie-category-block-hover-bg: #261434;
  --cc-cookie-category-block-hover-border: #261434;

  --cc-cookie-category-block-hover-border: #261434;
  --cc-cookie-category-expanded-block-bg: #261434;
  --cc-cookie-category-block-border: #261434;

  --cc-toggle-on-bg: #f2c301;
  --cc-toggle-off-bg: #30273b;
  --cc-toggle-on-knob-bg: #261434;
  --cc-toggle-off-knob-bg: #261434;
  --cc-toggle-enabled-icon-color: var(--cc-bg);
  --cc-toggle-disabled-icon-color: var(--cc-bg);
  --cc-toggle-readonly-bg: #f2c301;
  --cc-toggle-readonly-knob-bg: #30273b;
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);

  .pm__section--expandable .pm__section-arrow {
    /* display: none; */
  }

  .pm__section--expandable .pm__section-title {
    /* padding-left: 1rem; */
    /* pointer-events: none; */
  }

  .pm__section--toggle .pm__section-title {
    justify-content: flex-start !important;
  }

  .pm__section--toggle .pm__section-title,
  .pm__section--toggle .pm__section-desc-wrapper {
    border: none !important;
  }

  .pm__badge {
    pointer-events: none;
    background-color: #30273b !important;
    color: white !important;
    font-size: 11px !important;
    padding: 0.4rem 0.6rem !important;
  }

  .pm__section-title-wrapper {
    height: 60px !important;
  }

  .pm__section {
    color: #ffffff !important;
    margin-top: 0 !important;
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:hover {
      background-color: transparent !important;
    }
    a:hover {
      color: #f2c301 !important;
    }
  }

  .cm--box,
  .pm--box {
    background: #30273b !important;
    color: #ffffff !important;

    .cm__title {
      font-weight: 900 !important;
    }
    .cm__desc {
      color: #ffffff !important;
    }
  }

  .pm__btn,
  .cm__btn {
    &[data-role='necessary'] {
      background-color: #261434 !important;
      border-color: #261434 !important;
      color: #ffffff;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.cookie-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  left: 0;
  padding: 0.6rem;
  border-radius: 50%;
  background-color: #30273b;
  border: none;
  z-index: 998;
  transform: translateX(-45px);
  transition: all ease-in-out 0.4s;
}
.cookie-btn-visible {
  transform: translateX(20px);
  cursor: pointer;
  &:hover {
    transform: translateX(20px) scale(1.1);
  }
}
