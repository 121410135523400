/*Components*/
@import '../components/Header/style.pcss';

/*Partials*/
@import './partials/animation.pcss';
@import './partials/background.pcss';
@import './partials/cookie.pcss';
@import './partials/cursor.pcss';
@import './partials/form.pcss';
@import './partials/hero.pcss';
@import './partials/typography.pcss';

@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  @apply bg-primary text-white;
}

body {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.2px;
  font-family: 'Encode Sans', sans-serif;
  overflow-x: hidden;
}

.body-overflow-hidden {
  overflow: hidden;
  height: 100vh!improtant;
}

.grecaptcha-badge {
  display: none !important;
}

.panels-container {
  position: relative;
  height: 100vh;
}

.opacity-0 {
  opacity: 0;
}
.opacity-50 {
  opacity: 0.5;
}
.translateY-200px {
  transform: translateY(200px);
}
.translateY-100px {
  transform: translateY(100px);
}

.panel {
  height: 100vh;
  position: sticky;
  top: 0;
  flex-direction: column;
  justify-content: center;
}

.panel-img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 40vw;
  height: 600vh;
  z-index: 100;
}
.panel-img-content {
  position: relative;
  width: 100%;
  height: 600vh;
}
.panel-img {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.panel-inner-img {
  height: 100px;
  width: 100px;
  background: #06060e;
}

.autofill-transparent:-webkit-autofill,
.autofill-transparent:-webkit-autofill:hover,
.autofill-transparent:-webkit-autofill:focus,
.autofill-transparent:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.decimal-list {
  list-style: decimal;
  margin-left: 20px;
  margin-top: 20px;
}
.decimal-list li {
  margin-top: 12px;
}

.unordered-list {
  list-style: disc;
  margin-left: 20px;
  margin-top: 20px;
}
.unordered-list li {
  margin-top: 12px;
}

.swiper-pagination-bullet {
  border-radius: 2px !important;
  height: 4px !important;
  width: 24px !important;
  background-color: #fff !important;
}

.yarl__portal {
  z-index: 999999 !important;
}
