.bg-gradient {
  @apply text-white;
  background: #07071133;
  background: linear-gradient(90deg, #06060e 0%, #261434 100%);
}
.bg-gradient-reverse {
  @apply text-white;
  background: #070711;
  background: linear-gradient(90deg, #261434fe 0%, #06060efe 100%) !important;
}

.bg-white {
  @apply text-black;
}
