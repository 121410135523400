/* FORM HTML LABEL ADJUST */
label {
  @apply text-neutral-600;
}

label b {
  @apply text-white transition-all duration-300;
}

*:focus-within ~ label b {
  @apply text-primary;
}

*:autofill ~ label,
*:autofill ~ label b {
  @apply text-black;
}
